@primary-color: #EA7A3C;
@background: #efeaff;
@background-color: tranparent;
@background-image: linear-gradient(320deg, #2A4277 57%, #1F73B7 100%);
@input-bg: #5e6475;
@dark-color: #212942;
@almost-white: #f9f7ff;
@menu-width: 280px;
@menu-min-width: 180px;
@white: #ffffff;
@success: green;
@input-border: #d9d9d9;
@danger: rgba(255, 0, 0, 0.6);
@danger-hover: rgba(255, 0, 0, 1);
@primary-color-light: #FF9A61;

@import "_general";
@import "_homepage";
@import "_contract";
@import "_header-footer";
@import "_network-selector";
