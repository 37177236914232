.index-container {
    background-color: @background-color;
    background-image: @background-image;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background-color: @white;

    @media (min-width: 768px) {
        background-color: transparent;
    }

    .search-container {
        border-radius: 12px;
        position: relative;
        width: 100%;

        @media (min-width: 768px) {
            max-width: 470px;
            width: 90%;
            box-shadow: 0 0 15px -5px @primary-color;
        }

        .ant-input-affix-wrapper {
            padding-left: 0;
        }

        .ant-tabs {
            width: 335px;
            margin-left: 0;
        }

        .ant-select-selector {
            padding: 0;
        }

        background-color: @white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .search-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 365px;
            padding-top: 25px;

            @media (min-width: 768px) {
                margin-top: 60px;
                padding-top: 0;
            }

            img {
                width: 120px;
            }

            h1 {
                font-size: 48px;
                font-weight: 700;
                line-height: 64px;
                letter-spacing: 0;
                text-align: center;
                margin: 5px 0 0;
            }

            h3 {
                font-weight: 100;
                margin-top: 0;
                margin: 20px;
                text-align: center;
            }

            .search-tabs {
                margin-top: 10px;
                max-width: 360px;
                margin-left: 10px;
                margin-right: 10px;

                .ant-tabs-tab:hover {
                    color: @primary-color;
                }

                .ant-tabs-tab + .ant-tabs-tab {

                    @media (min-width: 400px) {
                        width: 150px;
                    }

                    text-align: center;
                    margin: 0 10px;
                }

                .ant-tabs-nav-wrap {
                    font-size: 14px;
                    line-height: 22px;
                    color: @dark-color;

                    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                        color: @dark-color;
                    }

                    .ant-tabs-tab-btn {
                        margin: 0 auto;
                    }

                    .ant-tabs-ink-bar {
                        background: @primary-color;
                    }
                }

                .ant-tabs-content-holder {
                    height: 120px;

                    .ant-tabs-tabpane {
                        display: flex;
                        flex-direction: column;
                        height: 140px;
                        opacity: 0;
                        transition: all 1s;
                    }

                    .ant-tabs-tabpane-active {
                        opacity: 1;
                        transition: all 1s;
                    }
                }
            }

            .quick-access-container {
                display: flex;
                flex-direction: column;
                justify-content: center;

                h5 {
                    text-align: center;
                }

                .contract-link-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .contract-link {
                        width: 33%;
                        text-align: center;
                        color: @primary-color;
                    }
                }
            }
        }

        .footer-items {
            width: 335px;
            margin-top: 60px;
        }
    }
}


/*
body {
  background-color: transparent;
  background-image: linear-gradient(320deg, #2A4277 57%, #1F73B7 100%);
}*/
