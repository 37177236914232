.side-contract-menu {
  background-color: @white;
  width: @menu-width;
  min-width: @menu-min-width;
  height: calc(100vh - 60px);
  padding: 35px 10px 0 20px;

  .side-contract-name {
    h2 {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
    }

    h4 {
      font-weight: 100;
      font-size: 14px;
      line-height: 17px;
    }
  }
}

.address-display a {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: @input-bg;
}

.no-methods-placeholder {
  color: @input-bg;
  display: flex;
  justify-content: center;
  font-size: 16px;

  border: 2px dashed #ae92ff;
  margin: 20px 20px 0;
  padding: 25px 20px;
  opacity: 0.5;
  border-radius: 12px;

  @media (min-width: 768px) {
    margin: 60px 30px 0;
    padding: 150px 20px;
    font-size: 20px;
  }

  .mobile {
    display: inherit;
    margin-left: 4px;
  }

  .desktop {
    display: none;
  }
}

.contract-column {
  @media (min-width: 768px) {
    width: calc(100vw - 280px);
  }


  &.contract-main {
    position: relative;
    order: 1;

    .contract-info {
      background-color: @white;
      border-radius: 8px;
      z-index: 100;
      right: 20px;
      top: 10px;
      width: 200px;

      .ant-collapse-item {
        border-bottom: none;
      }

      @media (min-width: 768px) {
        position: absolute;
        width: 300px;
      }
      cursor: pointer;

      svg {
        fill: @primary-color;
      }

      .anticon-info-circle svg {
        fill: @input-bg;
      }

      .ant-collapse-content-box {
        padding-left: 10px;

        .address-row {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      .ant-collapse-header {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: @dark-color;
        padding: 10px 0 10px 0;
        text-align: center;
        @media (min-width: 768px) {
          padding: 10px;
        }
      }

      .contract-variable-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: @dark-color;
      }

      .address-balance {
        margin-left: 10px;
      }

      .contract-variable-value {
        font-weight: 100;
        font-size: 12px;
        line-height: 20px;
        color: @dark-color;
      }
    }
  }

  h3 {
    margin-top: 20px;
    margin-left: 20px;
  }

  .function-container {
    padding-right: 20px;
    padding-left: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .contract-method {
      width: 100%;
      transition: all 0.3s;
      background-color: @white;
      border-radius: 12px;
      padding: 25px 16px;
      margin-bottom: 20px;
      position: relative;
      box-shadow: 0 0 9px -5px @primary-color;

      button.primary {
        margin: 0 auto;
        height: 32px;

        @media (min-width: 768px) {
          position: absolute;
          right: 16px;
          top: 22px;
          margin: 0;
        }

      }

      .contract-method-input {
        label {
          font-size: 12px;
          font-weight: 400;
          line-height: 30px;
          color: @input-bg;
          margin-bottom: 10px;
        }
      }

      .contract-method-name {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: @dark-color;

        .anticon {
          margin-right: 10px;
          line-height: 10px;
        }
      }

      .contract-result-output {
        font-weight: 500;
        font-size: 16px;
        width: 100%;
        color: @dark-color;
        border: none;
        position: relative;

        .result-icon {
          color: @success;
          position: absolute;
          top: 0;
          right: 0;
          font-size: 20px;
        }

        .result-text {
          position: absolute;
          top: 0;
          right: 0;
        }

        .ant-collapse-item > .ant-collapse-header {
          padding: 0 !important;
        }

        span {
          font-weight: 100;
        }
      }

      @media (max-width: 1000px) {
        width: 100%;
      }
    }

    @media (max-width: 720px) {
      display: block;
    }
  }

  .contract-header {
    display: flex;
    justify-content: space-between;

    .primary {
      height: 25px;
      margin-bottom: 20px;
      color: @white;
      background-color: @primary-color;
      border: 2px solid @primary-color;
      transition: all 0.3s ease-out;
      align-self: center;
      width: 150px;
      margin-right: 14px;

      &:hover {
        color: @primary-color;
        background-color: @white;
        border: 2px solid @primary-color;
        transition: all 0.3s ease-out;
      }
    }
  }
}

.contract-container-main {
  overflow-y: auto;
  height: calc(100vh - 70px);
  padding-top: 15px;

  ::-webkit-scrollbar {
    display: none;
  }

}

.contract-navigation-content {
  background-color: @white;
  padding: 0;
  max-width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    display: inherit;
  }

  /* width */

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    background: #e6e6e6;
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
  }

  /* Handle on hover */

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 50px;
  }

  .ant-collapse-ghost {
    margin-top: 10px;
    overflow-y: auto;
    padding-left: 15px;

    .ant-collapse-content-box {
      padding: 0;

      li {
        padding-left: 0;
      }
    }
  }

  .contract-navigation-title {
    white-space: nowrap;
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 15px;
    min-height: 90px;

    * {
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    h2 {
      margin: 0;
    }

    h4 span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }

    svg {
      fill: @primary-color;
    }
  }

  .ant-collapse-header {
    padding-left: 0 !important;
    padding-bottom: 0 !important;
  }

  .ant-collapse-ghost {
    flex-grow: 1;
  }

  .primary {
    height: 25px;
    margin-bottom: 20px;
    color: @primary-color;
    background-color: @white;
    border: 2px solid @primary-color;
    transition: all 0.3s ease-out;
    align-self: center;
    width: 150px;
    margin-right: 14px;

    &:hover {
      color: @primary-color;
      background-color: @white;
      border: 2px solid @primary-color;
      transition: all 0.3s ease-out;
    }
  }

  ul {
    padding: 0;

    li {
      width: 100%;
      height: 30px;
      list-style: none;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 10px;

      &.active {
        display: flex;
        justify-content: space-between;

        > span:nth-child(1) {
          width: 100%;
        }

        > span:nth-child(2) {
          svg {
            fill: @input-bg;
          }
        }

        background-color: @almost-white;
        border-radius: 4px;
      }

      cursor: pointer;

      &.header {
        font-weight: bold;
        padding: 0;
        margin-top: 10px;
      }
    }
  }
}

.contract-loading-spinner {
  margin: 60px auto 0;
}

.menu-button {
  margin-left: 10px;
  z-index: 100;
  font-size: 20px;
  position: absolute;
  left: 5px;
  top: 13px;
}

.info-button {
  margin-left: 10px;
  z-index: 100;
  font-size: 20px;
  position: absolute;
  right: 11px;
  top: 13px;
  @media (min-width: 768px) {
    top: 30px;
    right: 15px;
    background-color: @white;
    border-radius: 30px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.info-button-close {
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 999;
  font-size: 20px;
  @media (min-width: 768px) {
    top: 45px;
    right: 25px;
  }
}

.menu-button-close {
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 999;
  font-size: 20px;
}

.info-navigation {
  order: 1;

  @media (min-width: 991px) {
    order: 2;
  }

  height: auto;
  transition: all 0.3s;

  .contract-navigation-title {
    padding-top: 2px;
  }

  .address-row {
    padding: 18px;
    border-radius: 12px;
    background-color: @white;
    margin-right: 20px;
    margin-left: 20px;
    box-shadow: 0 0 9px -5px @primary-color;

    @media (min-width: 991px) {
      margin-right: 0;
      margin-left: 0;
    }
  }

}

.contract-info-title {
  margin-left: 20px;
  margin-top: 20px;

  @media (min-width: 992px) {
    margin-left: 0;
  }
}

.contract-navigation {
  transition: all 0.3s;
  min-width: 100%;

  @media (min-width: 768px) {
    min-width: 0;
    margin-top: 0;
  }

  &.open {
    display: block;
    position: absolute;
    z-index: 700;
    background-color: @white;

    @media (min-width: 768px) {
      min-width: 280px;
      padding-top: 0;
      .contract-navigation-content {
        width: 100%;
        margin: 0;
      }
    }
    transition: all 0.3s;

    .contract-navigation-title {
      padding-top: 2px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (min-width: 768px) {
  .menu-button-close,
  .menu-button {
    display: none;
  }

  .no-methods-placeholder {
    .mobile {
      display: none;
    }

    .desktop {

      display: inherit;
      margin-left: 4px;
    }
  }

}
